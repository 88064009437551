/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react";
import { Box, Grid, Heading, Paragraph, Text } from "grommet";

import Trail from "../_shared_/trail";
import FadeIn from "../_shared_/fade-in";
import TaxesIllustration from "./components/taxes.illustration";
import { Buttons } from "../../styles/components/button-effect";
import { SpringLink } from "../_shared_/transition";
import ScrollContext from "../../context/scroll-context";
import { mainBlack } from "../../styles/constants";
import styled from "styled-components"
import Cloud from "../../images/cloud.svg"

const CloudBox = styled.div`
  position: absolute;
  width: 80%;
  top: 15%;
  left: -10%;
  z-index: -1;
  opacity: 0.5;
`;

const TaxesLayout: React.FC<{ size: string }> = ({ size }) => {
  const context = useContext(ScrollContext);
  const desktop = size !== `small`;

  return (
    <>
    <Grid columns={desktop ? [`50%`, `50%`] : [`100%`]} rows="fit" gap="small">
      <Box>
        <Heading size="medium" margin={{ bottom: `8px` }}>
          {desktop && (
            <>
              <Trail text="Daňové priznanie a" />
              <Trail text="účtovná závierka" />
            </>
          )}
          {!desktop && <>Daňové priznanie a účtovná závierka</>}
        </Heading>
        <Paragraph margin={{ top: `0px`, bottom: `30px` }} size="large" style={{ opacity: 0.6 }} color={mainBlack}>
          pre každého
        </Paragraph>
        <FadeIn>
          <Text margin="0" size="large" weight="bold">
            Daňové priznanie fyzických osôb
          </Text>
          <Paragraph margin={{ bottom: `15px`, top: `5px` }} fill size="medium">
            Elektronické daňové priznanie pre daň z príjmov (živnostník, prenajímateľ, zamestnanec) za Vás vyplníme rýchlo a s plnou
            odbornosťou už za 40 €. Súčinnosť a konzultácia sú samozrejmosťou.
          </Paragraph>
          <FadeIn>
            <Text margin="0" size="large" weight="bold">
              Daňové priznanie právnických osôb a účtovná závierka
            </Text>
            <Paragraph margin={{ bottom: `15px`, top: `5px` }} fill size="medium">
              Daňové priznanie Vašej firmy spolu s účtovnou závierkou spracujeme v čo najkratšom čase s dôrazom na detail. Naši klienti majú
              neustále k dispozícií informácie o finančnej situácii, aby sme spoločne mohli včas prijať rozhodnutia pre efektívnu
              optimalizáciu.
            </Paragraph>
            <Buttons mt={12}>
              <SpringLink to="/" onClick={(): void => context.handleSetScrollTo(`contact`)}>
                Mám záujem
              </SpringLink>
            </Buttons>
          </FadeIn>
        </FadeIn>
      </Box>
      <TaxesIllustration size={size} />
    </Grid>
    <CloudBox>
      <Cloud />
    </CloudBox>
    </>
  );
};

export default TaxesLayout;
