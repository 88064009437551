/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "grommet"
import React from "react"
import Lottie from "react-lottie"
import { IllustrationWrapper } from "../../../styles/components/illustration"
import Collateral from "../../_shared_/collateral"

import useImportJson from "../../../hooks/import-json"

const TaxesIllustration: React.FC<{ size: string }> = ({ size }) => {
  const { animationData } = useImportJson(`dane`, [])

  return (
    <Box className="relative">
      <IllustrationWrapper size={size}>
        <Collateral>
          <Lottie options={{ animationData, loop: false }} />
        </Collateral>
      </IllustrationWrapper>
    </Box>
  )
}

export default TaxesIllustration
