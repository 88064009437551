/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"
import { Box, ResponsiveContext } from "grommet"
import FooterLayout from "../components/_shared_/footer"
import { Transition } from "../components/_shared_/transition"
import HeaderLayout from "../components/_shared_/header"
import TaxesLayout from "../components/sub/taxes"
import { Container } from "../styles/components/container"
import GrommetWrapper from "../components/_shared_/grommet"
import SEO from "../components/_shared_/seo"
import { Questions } from "../types/interface"
import QA from "../components/sub/components/qa"
import Cookies from "../components/_shared_/cookies"

const seoDescription = `Daňové priznanie fyzických osôb
Vyplníme za Vás elektronické daňové priznanie k dani z príjmov fyzických osôb (príjmy zo živnosti, SZČO, prenájmu, zamestnania a pod.) rýchlo, efektívne a spolahlivo. Toto všetko môžete mať už za 40,- €.

Daňové priznanie právnických osôb
Daňové priznanie za Vašu firmu spracujeme v čo najkratšom čase s dôrazom na detail, pričom konzultácia, daňové plánovanie a hľadanie východiskových riešení sú samozrejmosťou.

Účtovná závierka
Účtovná závierka ako súčasť daňového priznania informuje externé aj interné subjekty o majetku, záväzkoch, výnosnosti a finančnej situácii podniku. S nami budete vždy informovaní o číslach, ktoré Váš podnik vykazuje počas účtovného obdobia.
`

const taxesQA: Questions[] = [
  {
    question: `Dokáže daňové priznanie vyplniť každý ?`,
    answer: `S vyplnením daňového priznania by Vám mal rozhodne pomôcť odborník, vyhnete sa tak mnohým nepríjemnostiam.`,
  },
  {
    question: `Ako dlho trvá zostaviť účtovnú závierku ?`,
    answer: `Pri mikro účtovnej jednotke to je len pár hodín, no pri väčších firmách to trvá aj týždeň, vždy v závislosti od správnosti vedenia účtovníctva.`,
  },
  {
    question: `Prečo by ste sa mali rozhodnúť pre nás?`,
    answer: `Pretože sme flexibilní, na otázky klientov reagujeme okamžite a o zverené účtovníctvo sa staráme ako o svoje vlastné. A dôkazom sú spokojní klienti a ich odporúčania.`,
  },
]

const Taxes: React.FunctionComponent = () => {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <GrommetWrapper>
      <SEO
        title="Daňové priznanie a účtovná závierka | fyzické a právnické osoby - SlovAcc"
        description={seoDescription}
      />
      <Cookies />
      <ResponsiveContext.Consumer>
        {(size: string): JSX.Element => (
          <>
            <HeaderLayout size={size} />
            <Transition>
              <Container>
                <Box
                  justify="center"
                  height={size !== `small` ? `100vh` : `100%`}
                  margin={{ top: size !== `small` ? `0px` : `93px`, bottom: size !== `small` ? `0px` : `93px` }}
                >
                  <TaxesLayout size={size} />
                </Box>
                <QA size={size} data={taxesQA} />
              </Container>
              <Container minHeight="auto" fluid>
                <FooterLayout size={size} />
              </Container>
            </Transition>
          </>
        )}
      </ResponsiveContext.Consumer>
    </GrommetWrapper>
  )
}

export default Taxes
